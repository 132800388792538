import { GetMaxLengthValidationMsg } from '@ui/validations/helpers/utils';
import { useTranslation } from 'react-i18next';
import { boolean, object, string } from 'yup';
import { IdType } from './config';

export default (idType: IdType) => {
  const { t } = useTranslation();

  const maxLength = GetMaxLengthValidationMsg(
    t('task.pos_ident.form.id_number'),
    9
  );

  return object({
    agentAssuranceCheck: boolean().isTrue(),
    idValidityCheck: boolean().isTrue(),
    imageResolutionCheck: boolean().isTrue(),
    residencyForeignPassportCheck: boolean().isTrue(),
    idType: string().required(
      t('general.error_messages.validations.is_required')
    ),
    nationality: string().required(
      t('general.error_messages.validations.is_required')
    ),
    idNumber:
      idType === 'german-id' || idType === 'passport'
        ? string()
            .matches(/[\w\d]{9}/, {
              message: t('general.error_messages.validations.invalid'),
            })
            .max(9, maxLength)
        : string(),

    dateOfIssue: string().required(
      t('general.error_messages.validations.is_required')
    ),
    idIssuingAuthority: string().required(
      t('general.error_messages.validations.is_required')
    ),
    dateOfExpiry: string().required(
      t('general.error_messages.validations.is_required')
    ),
  });
};
