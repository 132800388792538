import { Text } from '@smart/react-components';
import { EventType, IEventData } from '@utils/analytics/pushToDataLayer';
import { useTracking } from '@utils/hooks/use-tracking';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import AdbIcon from '../AdbIcon/AdbIcon';
import { EntryPointProps } from './EntryPoint.config';
import './EntryPoint.scss';

const BASE_CLASS = 'adb-entry-point';

const EntryPoint = ({
  name,
  path,
  icon,
  reduceMargin,
  isClickable,
  onClick,
  disabled = false,
  id,
  dataLayerRegion,
}: EntryPointProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [extendDataLayer] = useTracking<IEventData>();
  const { customerId, sfCustomerId, sfOrgId, bpid } = params;
  const localUrl = window.location.href;

  const onClickEntryPoint = () => {
    navigate(path, {
      state: {
        customerId,
        sfCustomerId,
        sfOrgId,
        bpid,
      },
    });
  };

  const webTrack = () => {
    extendDataLayer(
      {
        type: 'navigation',
        region: dataLayerRegion || '',
        name: id,
        url: localUrl,
        destinationUrl: path,
      },
      EventType.CLICK
    );
  };

  return isClickable ? (
    <button
      onClick={() => {
        if (disabled) return;

        if (onClick) {
          if (dataLayerRegion) webTrack();
          onClick();
        } else {
          if (dataLayerRegion) webTrack();
          onClickEntryPoint();
        }
      }}
      className={classNames(`${BASE_CLASS} dark-theme`, {
        [`${BASE_CLASS}--disabled`]: disabled,
      })}
      tabIndex={0}
    >
      <AdbIcon icon={icon} size="large" />
      <div
        className={classNames(`${BASE_CLASS}__text`, {
          [`${BASE_CLASS}__text--reduce-margin`]: reduceMargin,
        })}
      >
        <Text
          variant="link-100"
          as="p"
          className={classNames({
            [`${BASE_CLASS}__text--disabled`]: disabled,
          })}
        >
          {name}
        </Text>
      </div>
    </button>
  ) : (
    <div
      className={classNames(`${BASE_CLASS} dark-theme`, {
        [`${BASE_CLASS}--disabled`]: disabled,
      })}
    >
      <AdbIcon icon={icon} size="large" />
      <div
        className={classNames(`${BASE_CLASS}__text`, {
          [`${BASE_CLASS}__text--reduce-margin`]: reduceMargin,
          [`${BASE_CLASS}__text--disabled`]: disabled,
        })}
      >
        <Text
          variant="link-100"
          as="p"
          className={classNames({
            [`${BASE_CLASS}__text--disabled`]: disabled,
          })}
        >
          {t(name)}
        </Text>
      </div>
    </div>
  );
};

export default EntryPoint;
