import { getFieldLabel } from '@smart/components-adb/adb-required-label/helper';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { Select, Text } from '@smart/react-components';
import { nonNullable } from '@ui/library/helpers/filters';
import { EventType, IProductEventData } from '@utils/analytics/pushToDataLayer';
import { useTracking } from '@utils/hooks/use-tracking';
import { useAgentContext } from 'contexts/agent-context';
import { useOutletAgentsQuery } from 'graphql/queries/outlet.generated';
import { useChangeOwnerMutation } from 'graphql/queries/tasks.generated';
import { useCurrentOutlet } from 'hooks/outlet';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { TaskActionProps } from './types';

const BASE_CLASS = 'adb-lead-actions-modal';

const EventInfoName = {
  Handover: 'handover.choose_an_expert.confirm',
  Test_Drive: 'test_drive.choose_an_expert.confirm',
  Lead: 'lead_request.choose_an_expert.confirm',
  POSIdent_Check: 'id_verification.choose_an_expert.confirm',
  Appointment: '',
  Service: '',
  Task: '',
};
export const ChangeOwnerModal = ({ task, onComplete }: TaskActionProps) => {
  const { t } = useTranslation();
  const outlet = useCurrentOutlet();
  const { agent } = useAgentContext();
  const [extendDataLayer] = useTracking<IProductEventData>();

  const [selectedAgentId, setSelectedAgentId] = useState<string>();

  const [changeOwner, { loading: isChangingOwner }] = useChangeOwnerMutation();

  const onChangeOwner = () => {
    console.log(task, 8888);
    extendDataLayer(
      {
        type: 'feature',
        region: 'tasks',
        name: (task.original?.type && EventInfoName[task.original?.type]) || '',
        product: [
          {
            id: task.original?.vehicle?.id || '',
            name: task.original?.vehicle?.productName || '',
            model: task.original?.vehicle?.model || '',
            color: [
              {
                interior: task.original?.vehicle?.interior || '',
                exterior: task.original?.vehicle?.exterior || '',
              },
            ],
          },
        ],
      },
      EventType.CLICK
    );
    changeOwner({
      variables: {
        input: {
          ownerId: selectedAgentId ?? '',
          taskId: task.original.id,
        },
      },
    }).then(() => {
      if (selectedAgentId === agent.id) {
        onComplete('single');
      } else {
        onComplete('all');
      }
    });
  };

  const { data: agentsData } = useOutletAgentsQuery({
    variables: {
      outletId: outlet?.mcsId ?? '',
    },
    skip: !outlet?.mcsId || !agent,
  });

  const getAgentSelectOptions: {
    label: string;
    value: string;
  }[] =
    agentsData?.outletAgents
      .map((a) => {
        if (!a.gigyaId || a.firstName === '' || a.lastName === '') {
          return null;
        }
        return {
          label: `${a.firstName} ${a.lastName}`,
          value: a.gigyaId,
        };
      })
      .filter(nonNullable) ?? [];

  const validationSchema = object().shape({
    agentId: string().required(),
  });

  return (
    <AdbDialog
      id={BASE_CLASS}
      className={BASE_CLASS}
      buttons={{
        primary: {
          label: t('feature_calendar.general.buttons.confirm'),
          onClick: onChangeOwner,
          disabled: isChangingOwner || !selectedAgentId,
          isLoading: isChangingOwner,
        },
      }}
    >
      <AdbDialog.Header>
        <Text variant="hl-100">{t('task.choose_an_expert')}</Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        {getAgentSelectOptions && (
          <Select
            id="agentId"
            className="adb-tasks__change-owner-select"
            name="agentId"
            label={getFieldLabel(
              validationSchema,
              'appointmentType',
              t('feature_calendar.general.smart_expert')
            )}
            items={[{ label: '', value: '' }, ...getAgentSelectOptions]}
            value={selectedAgentId ?? ''}
            onSelectionChange={(value) => {
              setSelectedAgentId(value);
            }}
          >
            {(items) =>
              items.map((item) => <Select.Item key={item.value} {...item} />)
            }
          </Select>
        )}
      </AdbDialog.Content>
    </AdbDialog>
  );
};
