import { NetworkStatus } from '@apollo/client';
import { Customer, CustomerType, DmsCustomer } from '@smart/adb-shared';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import AdbNotification from '@smart/components-adb/molecules/AdbNotification/AdbNotification';
import { SignalVariant } from '@smart/components-adb/molecules/AdbNotification/AdbNotification.config';
import { Button, Link } from '@smart/react-components';
import { CustomerSearchType } from '@store/customers/defaults';
import type { CustomerListProps, CustomerTable } from '@store/customers/types';
import classNames from 'classnames';
import { useAgentContext } from 'contexts/agent-context';
import {
  useSearchDmsCustomerLazyQuery,
  useSearchGlobalCustomerLazyQuery,
} from 'graphql/queries/customer.generated';
import { useAllCustomersQuery } from 'graphql/queries/customers.generated';
import { useCurrentOutletId } from 'hooks/outlet';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import { validateEmail } from 'pages/tasks/search/search.config';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConnectToOutletButton } from './ConnectToOutletBtn';
import './search.scss';
import Header from './TableHeader/TableHeader';

const CustomerSearchTable = lazy(() => import('./Table/Table'));

const BASE_CLASS = 'adb-customer-search';

const CustomerSearch = ({ isTestDrive = false }: CustomerListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [customerListData, setCustomerListData] = useState<CustomerTable[]>([]);

  const [currentSearchType, setCurrentSearchType] =
    useState<CustomerSearchType>(CustomerSearchType.LOCAL);

  const [showCustomerList, setShowCustomerList] = useState<boolean>(false);

  const [searchGlobalQuery, setSearchedGlobalQuery] = useState<string>('');

  const [searchDmsQuery, setSearchedDmsQuery] = useState<string>('');

  const [globalSearchInputError, setGlobalSearchInputError] =
    useState<boolean>(false);

  const [dmsSearchInputError, setDmsSearchInputError] =
    useState<boolean>(false);

  const [globalSearchEmptyText, setGlobalSearchEmptyText] = useState<string>(
    t('general.error_messages.empty_search_field')
  );

  const [globalFilter, setGlobalFilter] = useState('');

  const [notificationText, setNotificationText] = useState<string>('');
  const [notificationVariant, setNotificationVariant] =
    useState<SignalVariant>('success');

  const {
    data: outletCustomers,
    loading: outletCustomersLoading,
    refetch: refetchLocalCustomers,
    networkStatus,
  } = useAllCustomersQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const getPathNewCustomer = () => {
    if (isTestDrive) {
      return '/test-drive/confirmation/user-details/new-light-customer';
    }
    return '/customer/register';
  };

  const [searchDmsCustomer, { loading: searchDmsCustomerLoading }] =
    useSearchDmsCustomerLazyQuery();

  useEffect(() => {
    if (currentSearchType === CustomerSearchType.DMS && !searchDmsQuery) {
      setGlobalSearchEmptyText(t('general.error_messages.empty_search_dms'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, currentSearchType, searchDmsQuery]);

  // set empty layout text
  useEffect(() => {
    if (currentSearchType === CustomerSearchType.LOCAL) {
      setGlobalSearchEmptyText(
        `${t('general.error_messages.no_records_found')}.
          ${t('general.error_messages.no_records_found_info')}`
      );
    } else if (
      currentSearchType === CustomerSearchType.GLOBAL &&
      !searchGlobalQuery
    ) {
      setGlobalSearchEmptyText(t('general.error_messages.empty_search_field'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchType, t]);

  // get agent details
  const { agent: selectedAgent } = useAgentContext();

  /**
   * @method getTableDetails
   * This method is used to provide table configured data according to specific columns
   * @returns CustomerTable
   */
  const getGlobalTableDetails = (customerData: Customer[]): CustomerTable => {
    let customerTableDetails = {} as CustomerTable;

    if (!customerData.length) {
      return customerTableDetails;
    }

    customerData.forEach((customer) => {
      customerTableDetails = {
        ...customer,
        action: (
          <ConnectToOutletButton
            customer={customer}
            setCurrentSearchType={setCurrentSearchType}
          />
        ),
      };
    });

    return customerTableDetails;
  };

  /**
   * @method getLocalTableDetails
   * This method is used to provide table configured data according to specific columns (register button for leads)
   * @returns CustomerTable[]
   */
  const getLocalTableDetails = (
    customerData: (Customer | DmsCustomer)[]
  ): CustomerTable[] => {
    const customerTableDetails = [] as CustomerTable[];

    if (!customerData?.length) {
      return customerTableDetails;
    }

    customerData.forEach((item) => {
      customerTableDetails.push({
        ...item,
        ...(item.type === CustomerType.Lead && {
          action: (
            <Button
              onClick={() =>
                navigate(getPathNewCustomer(), {
                  state: {
                    uuid: item.uuid,
                    customer: item,
                    customerType: CustomerType.Lead,
                  },
                })
              }
              variant="secondary"
            >
              {t('customer.search.buttons.register')}
            </Button>
          ),
        }),
        ...(currentSearchType === CustomerSearchType.DMS && {
          action: (
            <Button
              onClick={() => {
                const dmsCustomer = item as DmsCustomer;
                return navigate(getPathNewCustomer(), {
                  state: {
                    dmsCustomer: {
                      customerId: dmsCustomer.uuid,
                      customerIdType: dmsCustomer.customerIdType,
                      outletMcsId: dmsCustomer.retailerId,
                      queryToken: dmsCustomer.queryToken,
                      retailerIdType: dmsCustomer.retailerIdType,
                      systemInstance: dmsCustomer.systemInstance,
                      systemName: dmsCustomer.systemName,
                    },
                    uuid: item.uuid,
                    customerType: CustomerType.Dms,
                  },
                });
              }}
              variant="secondary"
            >
              {t('customer.search.buttons.create_customer')}
            </Button>
          ),
        }),
      });
    });

    return customerTableDetails;
  };

  const [searchGlobalCustomer, { loading: globalCustomerSearchLoading }] =
    useSearchGlobalCustomerLazyQuery();

  const handleSubmitGlobalSearch = async (searchQuery: string) => {
    setSearchedGlobalQuery(searchQuery);

    if (!searchQuery || !validateEmail(searchQuery) || !selectedAgent) {
      setGlobalSearchInputError(true);
    } else {
      setGlobalSearchInputError(false);

      const res = await searchGlobalCustomer({
        variables: {
          userId: searchQuery.toString(),
        },
      });
      const customer = res.data?.searchGlobalCustomer;

      if (customer && customer.uuid !== null) {
        setCustomerListData([getGlobalTableDetails([customer])]);
      } else {
        setCustomerListData([]);
        setGlobalSearchEmptyText(t('general.error_messages.no_records_found'));
      }
    }
  };

  const outlet = useCurrentOutletId();

  const handleSubmitDmsSearch = async (searchQuery: string): Promise<void> => {
    if (searchQuery === '' || searchQuery.length < 2 || !selectedAgent) {
      setDmsSearchInputError(true);
      setNotificationText(t('customer.search.minimum_length'));
      setNotificationVariant('error');
    } else {
      setSearchedDmsQuery(searchQuery);
      setDmsSearchInputError(false);

      const res = await searchDmsCustomer({
        variables: {
          input: {
            outletMcsId: outlet?.mcsId ?? '',
            query: searchQuery.toString(),
          },
        },
      });

      setCustomerListData(
        getLocalTableDetails(res.data?.searchDmsCustomer.customers ?? [])
      );

      if (res.data?.searchDmsCustomer.customers.length === 0) {
        setGlobalSearchEmptyText(t('general.error_messages.no_records_found'));
      }
    }
  };

  const onSearchTypeChange = (searchType: CustomerSearchType) => {
    setGlobalSearchInputError(false);
    setDmsSearchInputError(false);
    setCurrentSearchType(searchType);
  };

  // used in test drive booking flow to search customer locally
  useEffect(() => {
    if (!globalFilter) {
      setShowCustomerList(false);
    }
  }, [globalFilter]);

  const showAllCustomersLink = (
    <div className={`${BASE_CLASS}__show-all`}>
      <div>
        <p>{t('customer.search.text_note')}</p>

        <Link asChild>
          <Button
            type="button"
            onClick={() => {
              setShowCustomerList(true);
            }}
          >
            {t('customer.search.buttons.show_all_customer')}
          </Button>
        </Link>
      </div>
    </div>
  );

  const customerDataIsLoading = () => {
    if (currentSearchType === CustomerSearchType.DMS) {
      return searchDmsCustomerLoading;
    }
    if (currentSearchType === CustomerSearchType.LOCAL) {
      return (
        networkStatus === NetworkStatus.loading ||
        networkStatus === NetworkStatus.refetch
      );
    }
    return globalCustomerSearchLoading;
  };

  const onRefresh = () => {
    refetchLocalCustomers();
  };

  return (
    <div
      className={classNames(BASE_CLASS, {
        [`${BASE_CLASS}--full-page`]: !isTestDrive,
      })}
    >
      <AdbLayout.Header backgroundColor="bg-level-2">
        <Header
          currentSearchType={currentSearchType}
          onSearchClick={
            currentSearchType === CustomerSearchType.GLOBAL
              ? handleSubmitGlobalSearch
              : handleSubmitDmsSearch
          }
          onSearchTypeChange={onSearchTypeChange}
          searchGlobalQuery={searchGlobalQuery}
          searchDmsQuery={searchDmsQuery}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          isTestDrivePage={isTestDrive}
          showGlobalSearchInputError={globalSearchInputError}
          showDmsSearchInputError={dmsSearchInputError}
          rows={
            currentSearchType === CustomerSearchType.LOCAL
              ? (outletCustomers?.allCustomers.customers.length ?? 0)
              : customerListData.length
          }
          onRefresh={onRefresh}
          isRefreshing={networkStatus === NetworkStatus.refetch}
        />
      </AdbLayout.Header>

      <AdbLayout.Content>
        {isTestDrive &&
        !showCustomerList &&
        !globalFilter &&
        currentSearchType === CustomerSearchType.LOCAL ? (
          showAllCustomersLink
        ) : (
          <Suspense fallback={<LoadingIndicator onFullPage />}>
            <CustomerSearchTable
              customerListData={
                currentSearchType === CustomerSearchType.LOCAL &&
                !outletCustomersLoading
                  ? getLocalTableDetails(
                      outletCustomers?.allCustomers?.customers ?? []
                    )
                  : customerListData
              }
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              showPaginationBar={
                currentSearchType === CustomerSearchType.LOCAL ||
                currentSearchType === CustomerSearchType.DMS
              }
              isLoading={customerDataIsLoading()}
              isTestDrive={isTestDrive}
              globalSearchEmptyText={globalSearchEmptyText}
              currentSearchType={currentSearchType}
            />
          </Suspense>
        )}
      </AdbLayout.Content>

      {notificationText && (
        <AdbNotification
          variant={notificationVariant}
          text={t(notificationText)}
          label={t(`customer.register.${notificationVariant}`)}
          onClose={() => setNotificationText('')}
          isVisible={!!notificationText}
        />
      )}
    </div>
  );
};

export default CustomerSearch;
