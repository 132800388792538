import type { LinkOut } from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut.config';

/**
 * @enum CustomerNavigationType
 * List of navigation items under customer management
 */
export enum CustomerNavigationType {
  OVERVIEW = 'Overview',
  DETAILS = 'Details',
  ACTIVITY = 'Activity',
  DOCUMENTS = 'Documents',
  GARAGE = 'Garage',
  ORDERS = 'Orders',
  PRODUCTS = 'Purchased products',
  CART = 'Shopping Cart',
}

/**
 * @enum OutletCustomerNavigationType
 * List of navigation items under outlet management
 */
enum OutletCustomerNavigationType {
  OVERVIEW = 'Overview',
  MY_CUSTOMERS = 'My Customers',
  ACTIVITY = 'Activity',
  STOCKCARS = 'Stockcars',
  SHOWROOM = 'Showroom',
  MY_PROFILE = 'My Profile',
}

/**
 * @enum CustomerNavItem
 * List of navigation items that can be of type outlet / customer management
 */
export type CustomerNavItem = LinkOut & {
  path: string;
  name: CustomerNavigationType | OutletCustomerNavigationType;
  order: number;
  title: string;
  disabled?: boolean;
  dataLayerName?: string;
  dataLayerRegion?: string;
};
