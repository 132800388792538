import { CreateLightAccountForm } from '@smart/components-adb/calendar/CreateAppointmentDialog/CreateLightAccountForm';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button } from '@smart/react-components';
import { EventType, IProductEventData } from '@utils/analytics/pushToDataLayer';
import { useTracking } from '@utils/hooks/use-tracking';
import { useTranslation } from 'react-i18next';
import { TaskActionProps } from '../types';

export const CreateLightAccountButton = ({
  task,
  disabled,
  onComplete,
}: TaskActionProps) => {
  const { t } = useTranslation();
  const { registerModal, closeModal } = useModal();
  const [extendDataLayer] = useTracking<IProductEventData>();

  const originalTask = task.original;

  const onCreated = () => {
    closeModal();
    onComplete('single');
  };

  return (
    <Button
      variant="primary"
      onClick={() => {
        extendDataLayer(
          {
            type: 'feature',
            region: 'tasks',
            name: 'lead_request.next_step.create_light_account',
            product: [
              {
                id: task.original?.vehicle?.id || '',
                name: task.original?.vehicle?.productName || '',
                model: task.original?.vehicle?.model || '',
                color: [
                  {
                    interior: task.original?.vehicle?.interior || '',
                    exterior: task.original?.vehicle?.exterior || '',
                  },
                ],
              },
            ],
          },
          EventType.CLICK
        );
        registerModal(
          <AdbDialog id="create-appointment-dialog">
            <AdbDialog.Content>
              <CreateLightAccountForm
                initialData={{
                  firstName: originalTask.customer.firstName,
                  lastName: originalTask.customer.lastName,
                  email: originalTask.customer.userId,
                  mobileNumber: originalTask.customer.phoneNumber ?? '',
                }}
                onCreated={onCreated}
              />
            </AdbDialog.Content>
          </AdbDialog>
        );
      }}
      disabled={disabled}
    >
      {t('customer.register.buttons.create_new_light_customer')}
    </Button>
  );
};
