import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button } from '@smart/react-components';
import { EventType, IProductEventData } from '@utils/analytics/pushToDataLayer';
import { useTracking } from '@utils/hooks/use-tracking';
import { CustomerDetailsState } from 'pages/customer/details/helper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TaskActionProps } from '../types';
import { getCustomerPath } from '../utils';

export const ConvertLeadButton = ({
  task,
  disabled,
  eventInfoName,
}: Omit<TaskActionProps, 'onComplete'>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { closeModal } = useModal();
  const [extendDataLayer] = useTracking<IProductEventData>();

  const { customer } = task.original;

  const isLeadWithoutOpportunity = customer.sfCustomerId?.startsWith('00Q');
  const taskCustomerIsFullAccount = task.original.customer.regType === 'Full';
  const isDisabled =
    !customer ||
    taskCustomerIsFullAccount ||
    isLeadWithoutOpportunity ||
    disabled;

  return (
    <Button
      disabled={isDisabled}
      onClick={() => {
        extendDataLayer(
          {
            type: 'feature',
            region: 'tasks',
            name: eventInfoName || '',
            product: [
              {
                id: task.original?.vehicle?.id || '',
                name: task.original?.vehicle?.productName || '',
                model: task.original?.vehicle?.model || '',
                color: [
                  {
                    interior: task.original?.vehicle?.interior || '',
                    exterior: task.original?.vehicle?.exterior || '',
                  },
                ],
              },
            ],
          },
          EventType.CLICK
        );
        closeModal();
        navigate(getCustomerPath(customer, 'details'), {
          state: {
            convertToFullAccount: true,
          },
        } as CustomerDetailsState);
      }}
      variant="primary"
    >
      {t('task.lead.convert_account_cta')}
    </Button>
  );
};
