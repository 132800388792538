import { Maybe } from '@smart/adb-shared';
import { SMART_COM_LINKS } from '@utils/configs/config';
import {
  CustomerNavigationType,
  CustomerNavItem,
} from '@utils/types/navigation';

const CustomerNavigation = (
  customerId: Maybe<string> | undefined,
  sfCustomerId: Maybe<string> | undefined,
  bpid: Maybe<string> | undefined = '',
  sfOrgId: Maybe<string> | undefined = ''
): CustomerNavItem[] => {
  const pathParams = `${customerId}/${sfCustomerId}${
    bpid && sfOrgId ? `/${bpid}/${sfOrgId}` : ''
  }`;

  return [
    {
      title: 'navigation.customer.overview',
      path: `/customer/overview/${pathParams}`,
      name: CustomerNavigationType.OVERVIEW,
      order: 1,
      dataLayerName: 'overview',
      dataLayerRegion: 'customer.sub_menu',
    },
    {
      title: 'navigation.customer.details',
      path: `/customer/details/${pathParams}`,
      name: CustomerNavigationType.DETAILS,
      order: 2,
      dataLayerName: 'details',
      dataLayerRegion: 'customer.sub_menu',
    },
    {
      title: 'navigation.customer.documents',
      path: `/customer/documents/${pathParams}`,
      name: CustomerNavigationType.DOCUMENTS,
      order: 3,
      dataLayerName: 'documents',
      dataLayerRegion: 'customer.sub_menu',
    },
    {
      title: 'navigation.customer.garage',
      path: '#',
      name: CustomerNavigationType.GARAGE,
      isLinkOut: true,
      redirectPath: SMART_COM_LINKS.garage,
      order: 5,
      dataLayerName: 'garage',
      dataLayerRegion: 'customer.sub_menu',
    },
    {
      title: 'navigation.customer.orders',
      path: `/customer/orders/${pathParams}`,
      name: CustomerNavigationType.ORDERS,
      order: 4,
      dataLayerName: 'orders',
      dataLayerRegion: 'customer.sub_menu',
    },
    {
      title: 'navigation.customer.products',
      path: `/customer/purchased-products/${pathParams}`,
      name: CustomerNavigationType.PRODUCTS,
      order: 5,
      dataLayerName: 'products',
      dataLayerRegion: 'customer.sub_menu',
    },
    {
      title: 'navigation.customer.cart',
      path: '#',
      name: CustomerNavigationType.CART,
      isLinkOut: true,
      redirectPath: SMART_COM_LINKS.shoppingCart,
      order: 6,
      dataLayerName: 'cart',
      dataLayerRegion: 'customer.sub_menu',
    },
  ];
};

export default CustomerNavigation;
