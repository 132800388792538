import {
  DataLayerPageViewEvent,
  EventCall,
  EventType,
  pushToDataLayer,
  TrackTypes,
} from '@utils/analytics/pushToDataLayer';
import { getCurrencyByMarket } from '@utils/getCurrencyByMarket';
import { useAgentContext } from 'contexts/agent-context';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { useCurrentOutlet } from 'hooks/outlet';
import { useCallback } from 'react';

export const useTracking = <T extends TrackTypes>() => {
  const { DEPLOY_ENV, BUILDKITE_COMMIT } = import.meta.env;
  const { agent } = useAgentContext();
  const { market } = useMarketContext();
  const { locale } = useLanguageContext();
  const outlet = useCurrentOutlet();

  const createDataObj = useCallback(
    (pageData: T, eventType: EventType) => {
      const setPageData = (type: EventType) => {
        if (type === EventType.PAGEVIEW) {
          return {
            page: { ...pageData },
          };
        }
        return {
          eventInfo: { ...pageData },
        };
      };

      const data = setPageData(eventType);

      const obj = {
        event: eventType,
        user: {
          loginStatus: 'logged_in',
          customerId: agent?.id,
          type: 'agent',
          channel: 'adb',
          outlet: [
            {
              id: outlet?.bpId ?? '',
              name: outlet?.name ?? '',
            },
          ],
        },
        application: {
          id: 'adb',
          version: BUILDKITE_COMMIT,
          env: DEPLOY_ENV,
          language: locale.split('-')[0],
          market,
          currency: getCurrencyByMarket(market),
        },
        ...data,
      } as DataLayerPageViewEvent<T>;
      pushToDataLayer(obj);
    },
    // Callback is not meant to be called outside extendDataLayer
    // eslint-disable-next-line
    []
  );

  const extendDataLayer: EventCall<T> = useCallback(
    (pageData: T, eventType: EventType = EventType.PAGEVIEW) => {
      createDataObj(pageData, eventType);
    },
    [createDataObj]
  );

  return [extendDataLayer];
};
