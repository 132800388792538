import { Button, Text } from '@smart/react-components';
import classNames from 'classnames';
import { useClickOutside } from 'hooks/useClickOutside';
import { useRef, useState } from 'react';
import { FilterDropdownProps, FilterProp } from './FilterDropdown.config';
import './FilterDropdown.scss';
import FilterItem from './FilterItem/FilterItem';

const BASE_CLASS = 'adb-filter-dropdown';

const FilterDropdown = ({
  label,
  items,
  onSelect,
  selectedFilter,
  defaultSelected,
  showSelectedFilter,
  yPos = 'top',
  showIcon = true,
  disableOverflowScroll = false,
  className,
}: FilterDropdownProps) => {
  const [open, setOpen] = useState(false);

  const dropdownRef = useRef(null);

  const onClick = () => {
    setOpen(!open);
  };

  const onFilterSelect = (filterItem: FilterProp) => {
    onSelect(filterItem, label.toString());
    setOpen(false);
  };

  useClickOutside(dropdownRef, () => {
    if (open) {
      setOpen(false);
    }
  });

  const checkIfSelected = (filterItem: FilterProp): boolean => {
    if (Array.isArray(selectedFilter)) {
      if (selectedFilter.length > 0)
        return selectedFilter.some(
          (filter) => filter.queryValue === filterItem.queryValue
        );
    } else return selectedFilter?.queryValue === filterItem.queryValue;

    return false;
  };

  const getFilterDisplayText = () => {
    if (typeof label === 'object') {
      if (showSelectedFilter && !Array.isArray(selectedFilter)) {
        return (
          <>
            {label} {selectedFilter?.displayName}
          </>
        );
      }
      return label;
    }

    const getDisplayText = () => {
      if (!showSelectedFilter) return '';
      if (Array.isArray(selectedFilter))
        return selectedFilter.map((sf) => sf.displayName).join(' + ');

      return selectedFilter?.displayName || '';
    };

    return (
      <Text variant="lbl-100" as="p">{`${label} ${getDisplayText()}`}</Text>
    );
  };

  return (
    <div ref={dropdownRef} className={classNames(BASE_CLASS, className)}>
      <Button
        variant="ghost"
        onClick={onClick}
        className={`${BASE_CLASS}__button`}
      >
        {getFilterDisplayText()}
        {showIcon && (
          <Button.Icon
            className={`${BASE_CLASS}__button-icon`}
            icon={open ? 'chevron-compact-up' : 'chevron-compact-down'}
          />
        )}
      </Button>
      {open && (
        <ul
          className={classNames(`${BASE_CLASS}__dropdown`, {
            [`${BASE_CLASS}__dropdown--${yPos}`]: yPos,
            [`${BASE_CLASS}__dropdown--overflow-scroll`]:
              !disableOverflowScroll,
          })}
        >
          {items.map((filterItem) => (
            <FilterItem
              key={filterItem.queryValue}
              filterItem={filterItem}
              isSelected={checkIfSelected(filterItem)}
              onFilterSelect={onFilterSelect}
              setOpen={setOpen}
              open={open}
              defaultSelected={defaultSelected}
              shouldShowCheckbox={Array.isArray(selectedFilter)}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default FilterDropdown;
