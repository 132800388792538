import { ListItem, UnOrderedList } from '@smart/components-adb/atoms/List/List';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import FilterDropdown from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown';
import { FilterProp } from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown.config';
import { useModal } from '@smart/components-adb/molecules/Modal';
import TopNavigation from '@smart/components-adb/molecules/TopNavigation/TopNavigation';
import {
  Button,
  Icon,
  IconButton,
  IconLink,
  Link,
  Logo,
  Text,
} from '@smart/react-components';

import type { FacetSortByValue } from '@ui/data-models/facet/facet.model';
import { LanguageCode } from '@ui/translations';
import { EventType, IEventData } from '@utils/analytics/pushToDataLayer';
import { GetSupportEmail } from '@utils/configs/config';
import { ADB_COOKIES } from '@utils/cookie/adb-cookies';
import { getCookie } from '@utils/cookie/cookie-configurator';
import { useTracking } from '@utils/hooks/use-tracking';
import {
  getLanguage,
  getMarketLanguages,
  MarketSelectOption,
} from '@utils/market/getter';
import classNames from 'classnames';
import { useCustomerContext } from 'contexts/customer-context';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { SectionHeaderProps } from '../SectionLayout.config';
import './SectionHeader.scss';

const BASE_CLASS = 'adb-section-header';

/**
 * SectionHeader component
 * @param NavItemTypes NavItemTypes
 * @returns <SectionHeader> ReactNode
 */

const SectionHeader = ({
  navItems,
  logOutAgent,
  darkMode,
  isCustomerLayout = false,
}: SectionHeaderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { DEPLOY_ENV } = import.meta.env;
  const { prevListingRoute } = useCustomerContext();

  const [switchableLanguageOptions, setSwitchableLanguageOptions] = useState<
    MarketSelectOption[]
  >([]);

  const { locale, setLanguage } = useLanguageContext();
  const { market } = useMarketContext();
  const [extendDataLayer] = useTracking<IEventData>();

  const supportUrl = `mailto:${GetSupportEmail()}?subject=${t(
    'support.email_subject'
  )}&body=Please%20describe%20your%20request%20as%20detailed%20as%20possible%20and%20add%20the%20steps%20to%20reproduce%20the%20bug%20you%20encountered.%0APlease%20be%20aware%20to%20not%20share%20any%20customer%20credentials%20or%20sensitive%20information.%0A%0A%0A%0A%0A----------------PLEASE%20ADD%20BELOW%20DATA-----------%0A%0AUser:%20%0A%0AOutlet:%20%0A%0AURL:%20%0A%0ABrowser:%20%0A%0AScreenshot:%20`;

  const handleClose = () => {
    navigate(prevListingRoute || '/');
  };

  const handleCalendarClick = () => {
    extendDataLayer(
      {
        type: 'navigation',
        region: 'main_nav',
        name: 'calendar',
        url: window.location.href,
        destinationUrl: '/calendar',
      },
      EventType.CLICK
    );
    navigate('/calendar');
  };

  const handleLanguageChange = (filter: FilterProp | undefined) => {
    const languageCode = filter?.queryValue;
    if (languageCode && setLanguage) {
      setLanguage(languageCode as LanguageCode);
    }
  };

  const isTestEnv = (): boolean => {
    const langSwitcherFlag = getCookie(ADB_COOKIES.LANG_SWITCHER);

    return (
      (!!langSwitcherFlag && langSwitcherFlag === 'true') ||
      DEPLOY_ENV === 'dev' ||
      DEPLOY_ENV === 'qa'
    );
  };

  // create filter option using switchableLanguages
  const getLanguageSwitcherOptions = (
    options: MarketSelectOption[]
  ): FacetSortByValue[] =>
    options.map((lang) => ({
      displayName: lang.displayName.toUpperCase(),
      queryValue: lang.value,
    }));

  useEffect(() => {
    if (!market) {
      return;
    }

    const availableLanguages = getMarketLanguages(market, isTestEnv());

    setSwitchableLanguageOptions(availableLanguages);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market, locale]);

  const selectedLanguage = getLanguage(market, locale);

  const { registerModal, closeModal } = useModal();

  return (
    <nav
      className={classNames(
        BASE_CLASS,
        {
          [`dark-theme`]: darkMode,
        },
        isCustomerLayout ? '' : `${BASE_CLASS}__main-header`
      )}
    >
      <UnOrderedList>
        <div className={`${BASE_CLASS}__logo-nav`}>
          <div
            className={classNames(
              `${BASE_CLASS}__wrap`,
              `${BASE_CLASS}__wrap__logo`,
              {
                [`dark-theme`]: darkMode,
              }
            )}
          >
            {!isCustomerLayout && (
              <ListItem
                content={
                  <NavLink to="/">
                    <Logo
                      logo="smart-logo-vertical"
                      label={t('general.labels.home')}
                    />
                  </NavLink>
                }
              />
            )}
          </div>
          <TopNavigation
            navItems={navItems}
            activeItem={location?.pathname}
            darkMode={darkMode}
          />
        </div>
        <div className={`${BASE_CLASS}__wrap ${BASE_CLASS}__wrap--icon`}>
          <ListItem
            content={
              isCustomerLayout ? (
                <div className={`${BASE_CLASS}__close-icon`}>
                  <IconButton
                    data-testid="close"
                    onClick={handleClose}
                    aria-label="calendar"
                    variant="ghost"
                    className={classNames(BASE_CLASS, {
                      [`dark-theme`]: darkMode,
                    })}
                    mode={200}
                  >
                    <Button.Icon
                      icon="close-without-circle"
                      aria-label="close-without-circle"
                    />
                  </IconButton>
                </div>
              ) : (
                <>
                  <IconLink
                    aria-label="support-link"
                    className={`${BASE_CLASS}__support-icon`}
                    variant="button-ghost"
                    href={supportUrl}
                  >
                    <Icon icon="support-help" mode={200} />
                  </IconLink>

                  <Button
                    asChild
                    className={`${BASE_CLASS}__support-text`}
                    variant="ghost"
                  >
                    <Link
                      href={supportUrl}
                      onClick={() => {
                        extendDataLayer(
                          {
                            type: 'navigation',
                            region: 'main_nav',
                            name: 'support_help',
                            url: window.location.href,
                            destinationUrl: '',
                          },
                          EventType.CLICK
                        );
                      }}
                    >
                      {t('support.support_text')}
                    </Link>
                  </Button>

                  {switchableLanguageOptions.length > 1 && (
                    // show if has more than one language option
                    <div className={`${BASE_CLASS}__ln-switcher`}>
                      <FilterDropdown
                        label=""
                        items={getLanguageSwitcherOptions(
                          switchableLanguageOptions
                        )}
                        onSelect={handleLanguageChange}
                        showSelectedFilter
                        selectedFilter={{
                          queryValue: selectedLanguage.locale,
                          displayName: selectedLanguage.displayCode
                            ? selectedLanguage.displayCode.toUpperCase()
                            : selectedLanguage.locale.toUpperCase(),
                        }}
                      />
                    </div>
                  )}
                  <div className={`${BASE_CLASS}__calendar-icon`}>
                    <IconButton
                      data-testid="calendar"
                      onClick={handleCalendarClick}
                      aria-label="calendar"
                      variant="ghost"
                      className={classNames(BASE_CLASS, {
                        [`dark-theme`]: darkMode,
                      })}
                      mode={200}
                    >
                      <Button.Icon icon="calendar" aria-label="calendar" />
                    </IconButton>
                  </div>
                  <div className={`${BASE_CLASS}__logout-icon`}>
                    <IconButton
                      data-testid="logout"
                      onClick={() =>
                        registerModal(
                          <AdbDialog
                            id="logout-dialog"
                            buttons={{
                              primary: {
                                label: t('dashboard.notifications.confirm'),
                                onClick: () => {
                                  logOutAgent();
                                  closeModal();
                                },
                                isLoading: false,
                              },
                              secondary: {
                                label: t('dashboard.notifications.close'),
                                onClick: closeModal,
                              },
                            }}
                          >
                            <AdbDialog.Header>
                              <Text variant="hl-100">
                                {t('dashboard.notifications.sign_out')}
                              </Text>
                            </AdbDialog.Header>
                            <AdbDialog.Content>
                              {t('dashboard.notifications.confirm_sign_out')}
                            </AdbDialog.Content>
                          </AdbDialog>
                        )
                      }
                      aria-label="logout"
                      variant="ghost"
                      className={classNames(BASE_CLASS, {
                        [`dark-theme`]: darkMode,
                      })}
                      mode={200}
                    >
                      <Button.Icon icon="log-out" aria-label="log-out" />
                    </IconButton>
                  </div>
                </>
              )
            }
            additionalClasses={`${BASE_CLASS}__icon-wrap`}
          />
        </div>
      </UnOrderedList>
    </nav>
  );
};

export default SectionHeader;
