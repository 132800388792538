import { Button } from '@smart/react-components';
import { EventType, IProductEventData } from '@utils/analytics/pushToDataLayer';
import { useTracking } from '@utils/hooks/use-tracking';
import { useCallAttemptMutation } from 'graphql/queries/tasks.generated';
import { useTranslation } from 'react-i18next';
import { TaskActionProps } from '../types';

export const CallAttemptsButton = ({
  task,
  onComplete,
  disabled,
}: TaskActionProps) => {
  const { t } = useTranslation();

  const [updateCallAttempts, { loading }] = useCallAttemptMutation();
  const [extendDataLayer] = useTracking<IProductEventData>();

  return (
    <Button
      variant="primary"
      onClick={() => {
        extendDataLayer(
          {
            type: 'feature',
            region: 'tasks',
            name: 'lead_request.next_step.call_again_later',
            product: [
              {
                id: task.original?.vehicle?.id || '',
                name: task.original?.vehicle?.productName || '',
                model: task.original?.vehicle?.model || '',
                color: [
                  {
                    interior: task.original?.vehicle?.interior || '',
                    exterior: task.original?.vehicle?.exterior || '',
                  },
                ],
              },
            ],
          },
          EventType.CLICK
        );
        updateCallAttempts({
          variables: {
            input: {
              taskId: task.original.id,
            },
          },
        }).then(() => onComplete('single'));
      }}
      loading={loading}
      disabled={disabled}
    >
      <Button.Spinner />
      {t('task.lead.call_attempts_cta')}
    </Button>
  );
};
