import { EntryPointProps } from '@smart/components-adb/atoms/EntryPoint/EntryPoint.config';
import { SMART_COM_LINKS } from '@utils/configs/config';
import { TFunction } from 'i18next';

export const getEntryPoints = (t: TFunction): EntryPointProps[] => [
  {
    id: 'new_test_drive',
    name: t('customer.overview.entry_points.new_test_drive'),
    path: '/test-drive/car-selection',
    icon: 'test-drive',
    dataLayerRegion: 'customer.entry_point',
  },
  {
    id: 'customizer',
    name: t('customer.overview.entry_points.customizer'),
    path: '#',
    icon: 'filter',
    isLinkOut: true,
    dataLayerRegion: 'customer.entry_point',
    redirectPath: SMART_COM_LINKS.customizer,
  },
  {
    id: 'smart_store',
    name: t('customer.overview.entry_points.smart_store'),
    path: '#',
    icon: 'shopping-cart',
    isLinkOut: true,
    dataLayerRegion: 'customer.entry_point',
    redirectPath: SMART_COM_LINKS.smartStore,
  },
];

export const getFleetCustomerEntryPoints = (
  t: TFunction
): EntryPointProps[] => [
  {
    id: 'fleet_order',
    name: t('customer.overview.entry_points.fleet_order'),
    path: SMART_COM_LINKS.fleetOrder,
    redirectPath: SMART_COM_LINKS.fleetOrder,
    icon: 'shopping-cart',
    isLinkOut: true,
  },
  ...getEntryPoints(t).slice(1),
];

export const getOutletCustomerEntryPoints = (
  t: TFunction
): EntryPointProps[] => [
  {
    id: 'demo_stock_search',
    name: t('outlet.showroom.demo_cars.button.order'),
    path: '#',
    icon: 'car',
    isLinkOut: true,
    redirectPath: SMART_COM_LINKS.stockSearch,
  },
  {
    id: 'showroom_stock_search',
    name: t('outlet.showroom.order_button'),
    path: '#',
    icon: 'car',
    isLinkOut: true,
    redirectPath: SMART_COM_LINKS.stockSearch,
  },
];

export interface OverviewState {
  key: string;
  prevPage: string;
}
