import { AdbTextInput } from '@components/adb-required-label/AdbTextInput';
import PreferredLanguageSelect from '@features/PreferredLanguageSelect';
import {
  AddressFull,
  CustomerAccountType,
  CustomerType,
} from '@smart/adb-shared';
import { getFieldLabel } from '@smart/components-adb/adb-required-label/helper';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import Section from '@smart/components-adb/atoms/Section/Section';
import {
  ComboBox,
  RadioGroup,
  Select,
  SelectItemProps,
  Switch,
  useDebounce,
} from '@smart/react-components';
import useSelect from '@utils/hooks/useSelect';
import {
  getPhoneNumberHintForMarket,
  MarketCode,
  MarketCodePhonePrefixMap,
} from '@utils/market/types';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { Form as FormikForm, useFormikContext } from 'formik';
import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressValidator } from '../AddressValidator/app';
import {
  BusinessCustomerFormValues,
  CustomerFormTypes,
  mapAddressValidatorData,
  PrivateCustomerFormValues,
} from '../helper';
import { usePreferredOptionsQuery } from '../queries.generated';
import {
  FormProps,
  getCompanyRegistrationNumberLabel,
} from './BasicInfo.config';
import BasicInfoSchema from './BasicInfoSchema';
import DeclarationText from './DeclarationText/DeclarationText';
import { useRegionsQuery, useSearchCompaniesQuery } from './queries.generated';

const AdbPhoneInput = lazy(
  () => import('@smart/components-adb/molecules/AdbPhoneInput/AdbPhoneInput')
);

const BASE_CLASS = 'adb-customer-basic-info';

const Form = ({
  bindSubmitForm,
  checkDataValidity,
  formFooter,
  radioButtonTab,
  isNotAccordion,
  title,
  consent,
  onConsentChange,
  disableAllFields,
  convertToFullAccount,
  setAccountType,
}: FormProps) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldTouched,
    setFieldValue,
    submitForm,
    handleSubmit,
    dirty,
    setValues,
  } = useFormikContext<
    PrivateCustomerFormValues | BusinessCustomerFormValues
  >();
  const { t } = useTranslation();
  const { getTranslatedFilters } = useSelect();
  const { market } = useMarketContext();
  const { locale } = useLanguageContext();

  const isPrivateCustomer = values.accountType === CustomerAccountType.Private;

  const validationSchema = BasicInfoSchema(
    market,
    values.accountType ?? CustomerAccountType.Private
  );

  const currentTimestamp = new Date().toISOString();
  const validMarketsForVAT: MarketCode[] = ['es', 'it', 'pt'];

  const { data } = usePreferredOptionsQuery();

  const titleOptions = data?.preferredOptions.titleCode ?? [];
  const countryCodeOptions = data?.preferredOptions.countryCode ?? [];

  const companyRegistrationNumberLabel = getCompanyRegistrationNumberLabel(
    market as MarketCode
  );
  const { data: regions } = useRegionsQuery();
  const selectedRegionCodeOptions = useMemo(
    () => regions?.regions ?? [],
    [regions]
  ) as SelectItemProps[];

  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { data: searchCompanies, loading } = useSearchCompaniesQuery({
    variables: {
      input: {
        term: debouncedSearchTerm,
      },
    },
    skip:
      !debouncedSearchTerm ||
      values.accountType !== CustomerAccountType.Business,
  });

  const [validatedAddress, setValidatedAddress] = useState<AddressFull>();

  useEffect(() => {
    if (validatedAddress) {
      setValues((prev) => ({
        ...prev,
        ...mapAddressValidatorData(validatedAddress, selectedRegionCodeOptions),
      }));
    }
  }, [validatedAddress, selectedRegionCodeOptions, setValues]);

  const onChange = () => {
    if (checkDataValidity) {
      checkDataValidity(values);
    }
  };

  const onBlur = (
    event: React.FormEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.currentTarget;
    if (name && value) {
      setFieldTouched(name, true, false);
      setFieldValue(name, value.trim(), true);
    }
  };

  if (bindSubmitForm) {
    bindSubmitForm(submitForm);
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) =>
    handleSubmit(event);

  const enableFooter: boolean =
    values.type === CustomerType.Dms ||
    values.type === CustomerType.Lead ||
    dirty;

  const onChangeAccountType = (value: string) => {
    setValues(
      (prev: PrivateCustomerFormValues | BusinessCustomerFormValues) => {
        const sharedPrev = {
          firstName: prev.firstName,
          lastName: prev.lastName,
          userId: prev.userId,
          street: prev.street,
          town: prev.town,
          countryCode: prev.countryCode,
          postalCode: prev.postalCode,
          regionCode: prev.regionCode,
          mobileNumber: prev.mobileNumber,
          titleCode: prev.titleCode,
          uuid: prev.uuid,
          market: prev.market,
          recordType: prev.recordType,
          preferredLanguage: prev.preferredLanguage,
        };
        if (value === CustomerAccountType.Private) {
          setAccountType(CustomerAccountType.Private);
          return {
            accountType: CustomerAccountType.Private,
            ...sharedPrev,
          };
        }
        if (value === CustomerAccountType.Business) {
          const castPrev = prev as BusinessCustomerFormValues;
          setAccountType(CustomerAccountType.Business);
          return {
            accountType: CustomerAccountType.Business,
            sfOrgId: castPrev.sfOrgId ?? '',
            bpid: castPrev.bpid ?? '',
            companyName: castPrev.companyName ?? '',
            companyRegistrationNumber: castPrev.companyRegistrationNumber ?? '',
            vatRegistrationNumber: castPrev.vatRegistrationNumber,

            ...sharedPrev,
          };
        }
        return prev;
      }
    );
  };

  const convertAccountToggle = (
    <Switch
      id={`${BASE_CLASS}-account-type-toggle`}
      checked={values.accountType === CustomerAccountType.Business}
      name="accountType"
      label-id="label-id"
      onCheckedChange={(checked) =>
        onChangeAccountType(
          checked ? CustomerAccountType.Business : CustomerAccountType.Private
        )
      }
    >
      {t('customer.details.convert_account.business_toggle_switch')}
    </Switch>
  );

  return (
    <FormikForm autoComplete="on" onSubmit={onSubmit} onChange={onChange}>
      <Section
        expanded
        title={title}
        hasAccordion={!isNotAccordion}
        convertAccountToggle={convertToFullAccount && convertAccountToggle}
      >
        {radioButtonTab && (
          <RadioGroup
            key={values.accountType}
            name="accountType"
            onValueChange={onChangeAccountType}
            className={`${BASE_CLASS}__customer-type-group`}
          >
            <div className={`${BASE_CLASS}__customer-type`}>
              <RadioGroup.Radio
                id="private"
                value={CustomerAccountType.Private}
                checked={values.accountType === CustomerAccountType.Private}
              >
                {t('form_fields.basic.private')}
              </RadioGroup.Radio>
              <RadioGroup.Radio
                id="business"
                value={CustomerAccountType.Business}
                checked={values.accountType === CustomerAccountType.Business}
              >
                {t('form_fields.basic.business')}
              </RadioGroup.Radio>
            </div>
          </RadioGroup>
        )}
        {values.accountType === CustomerAccountType.Business && (
          <div
            className={`${BASE_CLASS}__form-wrap ${BASE_CLASS}__form-wrap--three-columns-2-1-1`}
          >
            <div className={`${BASE_CLASS}__form-input`}>
              <ComboBox
                id="company_name"
                name="companyName"
                key="companyName"
                label={t('form_fields.business.company_name')}
                value={
                  'companyName' in values && values.companyName
                    ? values.companyName
                    : ''
                }
                onChange={async (e) => {
                  setSearchTerm(e.target.value);
                  setFieldValue('companyName', e.target.value);
                }}
                loading={loading}
                error={
                  'companyName' in values &&
                  'companyName' in touched &&
                  'companyName' in errors && (
                    <ErrorField errorMsg={errors.companyName ?? ''} />
                  )
                }
                disabled={disableAllFields}
                invalid={'companyName' in errors && !!errors.companyName}
                filterItems={false}
                items={
                  (searchCompanies?.searchCompany?.result || []).map((r) => ({
                    value: r.name ?? '',
                  })) ?? []
                }
                onSelectedValueChange={(e) => {
                  const setValue = (name: string, value: string) => {
                    setFieldValue(name, value, true);
                  };

                  const company = searchCompanies?.searchCompany?.result.find(
                    (c) => c.name === e
                  );

                  setValue('companyName', e);
                  if (company) {
                    setValues((prev) => ({
                      ...prev,
                      bpid: company.bpid ?? '',
                      postalCode: company.info.zip_code[0],
                      countryCode: company.info.country[0],
                      street: company.info.street_address[0],
                      town: company.info.city[0],
                      regionCode: company.info.state[0],
                      companyName: e,
                    }));
                  }
                }}
              >
                {(items) => (
                  <>
                    {(items || []).map((item, index) => (
                      // eslint-disable-next-line
                      <ComboBox.Item key={`${item.value}-${index}`} {...item} />
                    ))}
                  </>
                )}
              </ComboBox>
              {}
            </div>
            {market !== 'pt' && (
              <div className={`${BASE_CLASS}__form-input`}>
                <AdbTextInput
                  id="companyRegistrationNumber"
                  type="text"
                  name="companyRegistrationNumber"
                  key="companyRegistrationNumber"
                  value={
                    'companyRegistrationNumber' in values &&
                    values.companyRegistrationNumber
                      ? values.companyRegistrationNumber
                      : ''
                  }
                  label={companyRegistrationNumberLabel}
                  onChange={handleChange}
                  onBlur={onBlur}
                  disabled
                  schema={validationSchema}
                />
              </div>
            )}
            {'vatRegistrationNumber' in values && (
              <div className={`${BASE_CLASS}__form-input`}>
                <AdbTextInput
                  id="vatRegistrationNumber"
                  type="text"
                  name="vatRegistrationNumber"
                  key="vatRegistrationNumber"
                  value={values.vatRegistrationNumber ?? ''}
                  label={t('form_fields.business.vat')}
                  onChange={handleChange}
                  onBlur={onBlur}
                  schema={validationSchema}
                  disabled={disableAllFields}
                />
                {(values.vatRegistrationNumber ||
                  ('vatRegistrationNumber' in touched &&
                    touched.vatRegistrationNumber)) &&
                  'vatRegistrationNumber' in errors &&
                  errors.vatRegistrationNumber && (
                    <ErrorField errorMsg={errors.vatRegistrationNumber} />
                  )}
              </div>
            )}
          </div>
        )}
        <div
          className={`${BASE_CLASS}__form-wrap ${BASE_CLASS}__form-wrap--name-row${
            validMarketsForVAT.includes(market) && '-vat'
          }`}
        >
          {market !== 'se' && (
            <div className={`${BASE_CLASS}__form-input`}>
              <Select
                id="title_code"
                name="titleCode"
                label={getFieldLabel(
                  validationSchema,
                  'titleCode',
                  t('form_fields.basic.salutation')
                )}
                value={values.titleCode ?? ''}
                onSelectionChange={(value) =>
                  setFieldValue('titleCode', value, true)
                }
                items={
                  locale === 'en-GB'
                    ? titleOptions
                    : getTranslatedFilters(titleOptions, 'title')
                }
                disabled={disableAllFields}
              >
                {(items) =>
                  (items || []).map((item) => (
                    <Select.Item key={item.value} {...item} />
                  ))
                }
              </Select>
              {errors.titleCode && touched.titleCode && (
                <ErrorField errorMsg={errors.titleCode} />
              )}
            </div>
          )}
          <div className={`${BASE_CLASS}__form-input`}>
            <AdbTextInput
              id="customer_first_name"
              type="text"
              name="firstName"
              label={t('form_fields.basic.first_name')}
              value={values.firstName}
              onChange={handleChange}
              onBlur={onBlur}
              schema={validationSchema}
              disabled={disableAllFields}
            />
            {(values.firstName || touched.firstName) && errors.firstName && (
              <ErrorField errorMsg={errors.firstName} />
            )}
          </div>
          <div className={`${BASE_CLASS}__form-input`}>
            <AdbTextInput
              id="customer_last_name"
              type="text"
              name="lastName"
              label={t('form_fields.basic.last_name')}
              value={values.lastName}
              onChange={handleChange}
              onBlur={onBlur}
              schema={validationSchema}
              disabled={disableAllFields}
            />
            {(values.lastName || touched.lastName) && errors.lastName && (
              <ErrorField errorMsg={errors.lastName} />
            )}
          </div>
          {validMarketsForVAT.includes(market) &&
            'vatRegistrationNumber' in values && (
              <div className={`${BASE_CLASS}__form-input`}>
                <AdbTextInput
                  id="customer_vat_number"
                  type="text"
                  name="vatRegistrationNumber"
                  disabled
                  label={t('form_fields.basic.private_vat_number')}
                  value={values.vatRegistrationNumber ?? ''}
                  onChange={handleChange}
                  onBlur={onBlur}
                  schema={validationSchema}
                />
                {(values.vatRegistrationNumber ||
                  ('vatRegistrationNumber' in touched &&
                    touched.vatRegistrationNumber)) &&
                  'vatRegistrationNumber' in errors &&
                  errors.vatRegistrationNumber && (
                    <ErrorField errorMsg={errors.vatRegistrationNumber} />
                  )}
              </div>
            )}
        </div>
        {!disableAllFields ? (
          <div
            className={`${BASE_CLASS}__form-wrap ${BASE_CLASS}__form-wrap--one-column`}
          >
            <div className={`${BASE_CLASS}__form-input`}>
              <AddressValidator
                key={currentTimestamp}
                onValidatedAddress={(validatedAddressData) =>
                  setValidatedAddress(validatedAddressData)
                }
              />
            </div>
          </div>
        ) : null}
        <div
          className={`${BASE_CLASS}__form-wrap ${BASE_CLASS}__form-wrap--one-column`}
        >
          <div className={`${BASE_CLASS}__form-input`}>
            <AdbTextInput
              id="customer_street"
              type="text"
              name="street"
              label={t('form_fields.basic.street_and_number')}
              value={values.street ?? ''}
              onChange={handleChange}
              onBlur={onBlur}
              schema={validationSchema}
              disabled={disableAllFields}
            />
            {(values.street || touched.street) && errors.street && (
              <ErrorField errorMsg={errors.street} />
            )}
          </div>
        </div>
        <div
          className={`${BASE_CLASS}__form-wrap ${BASE_CLASS}__form-wrap--two-columns-1-1`}
        >
          <div className={`${BASE_CLASS}__form-input`}>
            <AdbTextInput
              id="customer_postal_code"
              type="text"
              name="postalCode"
              label={t('form_fields.basic.postal_code')}
              value={values.postalCode ?? ''}
              onChange={handleChange}
              onBlur={onBlur}
              schema={validationSchema}
              disabled={disableAllFields}
            />
            {(values.postalCode || touched.postalCode) && errors.postalCode && (
              <ErrorField errorMsg={errors.postalCode} />
            )}
          </div>
          <div className={`${BASE_CLASS}__form-input`}>
            <AdbTextInput
              id="customer_town"
              type="text"
              name="town"
              label={t('form_fields.basic.town')}
              value={values.town ?? ''}
              onChange={handleChange}
              onBlur={onBlur}
              schema={validationSchema}
              disabled={disableAllFields}
            />
            {(values.town || touched.town) && errors.town && (
              <ErrorField errorMsg={errors.town} />
            )}
          </div>
        </div>
        <div
          className={`${BASE_CLASS}__form-wrap ${BASE_CLASS}__form-wrap--two-columns-1-1`}
        >
          <div className={`${BASE_CLASS}__form-input`}>
            <Select
              id="regionCode"
              name="regionCode"
              label={getFieldLabel(
                validationSchema,
                'regionCode',
                t('form_fields.basic.region')
              )}
              value={values.regionCode ?? ''}
              onSelectionChange={(value) =>
                setFieldValue('regionCode', value, true)
              }
              items={selectedRegionCodeOptions}
              disabled={disableAllFields}
            >
              {(items) =>
                (items || []).map((item) => (
                  <Select.Item key={item.value} {...item} />
                ))
              }
            </Select>
            {(values.regionCode || touched.regionCode) && errors.regionCode && (
              <ErrorField errorMsg={errors.regionCode} />
            )}
          </div>
          <div className={`${BASE_CLASS}__form-input`}>
            <Select
              id="countryCode"
              name="countryCode"
              label={t('form_fields.basic.country')}
              value={values.countryCode ?? ''}
              onSelectionChange={(value) =>
                setFieldValue('countryCode', value, true)
              }
              items={
                locale === 'en-GB'
                  ? countryCodeOptions
                  : getTranslatedFilters(countryCodeOptions, 'country_code')
              }
              disabled
            >
              {(items) => (
                <>
                  {(items || []).map((item) => (
                    <Select.Item key={item.value} {...item} />
                  ))}
                </>
              )}
            </Select>
            {(values.countryCode || touched.countryCode) &&
              errors.countryCode && (
                <ErrorField errorMsg={errors.countryCode} />
              )}
          </div>
        </div>
        <div
          className={`${BASE_CLASS}__form-wrap ${BASE_CLASS}__form-wrap--two-columns-1-1`}
        >
          <div className={`${BASE_CLASS}__form-input`}>
            <AdbTextInput
              id="customer_email"
              type="text"
              name="userId"
              label={t('form_fields.basic.email')}
              value={values.userId ?? ''}
              onChange={handleChange}
              onBlur={onBlur}
              disabled={
                !!values.uuid || disableAllFields || convertToFullAccount
              }
              schema={validationSchema}
            />
            {(values.userId || touched.userId) && errors.userId && (
              <ErrorField errorMsg={errors.userId} />
            )}
          </div>
          <div className={`${BASE_CLASS}__form-input`}>
            <Suspense fallback={<LoadingIndicator />}>
              <AdbPhoneInput
                id="customer_mobile"
                name="mobileNumber"
                label={getFieldLabel(
                  validationSchema,
                  'mobileNumber',
                  t('form_fields.basic.mobile')
                )}
                value={values.mobileNumber ?? ''}
                onChange={(value: string) =>
                  setFieldValue('mobileNumber', value)
                }
                message={t('form_fields.basic.mobile_hint', {
                  prefix: MarketCodePhonePrefixMap[market],
                  phoneNumber: getPhoneNumberHintForMarket(market),
                })}
                disabled={disableAllFields}
              />
            </Suspense>
            {(values.mobileNumber || touched.mobileNumber) &&
              errors.mobileNumber && (
                <ErrorField errorMsg={errors.mobileNumber} />
              )}
          </div>
        </div>
        <div
          className={`${BASE_CLASS}__form-wrap ${BASE_CLASS}__form-wrap--two-columns-1-1`}
        >
          <div className={`${BASE_CLASS}__form-input`}>
            <PreferredLanguageSelect
              value={values.preferredLanguage ?? ''}
              handleChange={(value) => {
                setFieldTouched('preferredLanguage', true, false);
                setFieldValue('preferredLanguage', value, true);
              }}
              disabled={disableAllFields}
              schema={validationSchema}
            />
            {errors.preferredLanguage && touched.preferredLanguage && (
              <ErrorField errorMsg={errors.preferredLanguage} />
            )}
          </div>
        </div>
        {!values.uuid && consent !== undefined && onConsentChange && (
          <DeclarationText
            consent={consent}
            onConsentChange={onConsentChange}
            accountType={values.accountType ?? CustomerAccountType.Private}
          />
        )}
        {formFooter &&
          formFooter(
            isPrivateCustomer
              ? CustomerFormTypes.BASIC_INFO_PRIVATE
              : CustomerFormTypes.BASIC_INFO_BUSINESS,
            !enableFooter
          )}
      </Section>
    </FormikForm>
  );
};

export default Form;
