import { useFeatureFlag } from '@utils/configs/featureFlag';

export const useAgentDashboardNavigation = () => {
  const useOutletDocuments = useFeatureFlag({
    defaultValue: false,
    key: 'use-outlet-documents',
  });

  const useAldCarts = useFeatureFlag({
    defaultValue: false,
    key: 'use-aldCarts',
  });

  return [
    {
      title: 'navigation.dashboard.tasks',
      path: '/tasks',
      name: 'navigation.dashboard.tasks',
      order: 1,
      dataLayerName: 'tasks',
      dataLayerRegion: 'main_nav',
    },
    {
      title: 'navigation.dashboard.customers',
      path: '/customer',
      name: 'navigation.dashboard.customers',
      order: 3,
      dataLayerName: 'customer',
      dataLayerRegion: 'main_nav',
    },
    {
      title: 'navigation.dashboard.orders',
      path: '/orders',
      name: 'navigation.dashboard.orders',
      order: 4,
      dataLayerName: 'orders',
      dataLayerRegion: 'main_nav',
      ...(useAldCarts && {
        pageItems: [
          {
            path: '/orders',
            headline: 'navigation.dashboard.orders',
          },
          {
            path: '/carts',
            headline: 'orders.ald_carts.carts',
          },
        ],
      }),
    },
    {
      title: 'navigation.dashboard.stock',
      path: '/stock',
      name: 'navigation.dashboard.stock',
      order: 5,
      dataLayerName: 'stock',
      dataLayerRegion: 'main_nav',
    },
    {
      title: 'navigation.dashboard.outlet',
      path: '/outlet/outlet-cars',
      name: 'navigation.dashboard.outlet',
      order: 6,
      dataLayerName: 'showroom',
      dataLayerRegion: 'main_nav',
      pageItems: [
        {
          path: '/outlet/outlet-cars',
          headline: 'navigation.outlet.outlet_cars',
          dataLayerName: 'outlet_cars',
          dataLayerRegion: 'showroom.sub_menu',
        },
        {
          path: '/outlet/order-status',
          headline: 'navigation.outlet.order_status',
          dataLayerName: 'order_status',
          dataLayerRegion: 'showroom.sub_menu',
        },
        {
          path: '/outlet/details-and-team',
          headline: 'navigation.outlet.details_and_team',
          dataLayerName: 'details_and_team',
          dataLayerRegion: 'showroom.sub_menu',
        },
        ...(useOutletDocuments
          ? [
              {
                path: '/outlet/documents',
                headline: 'navigation.customer.documents',
                dataLayerName: 'documents',
                dataLayerRegion: 'showroom.sub_menu',
              },
            ]
          : []),
      ],
    },
  ];
};
