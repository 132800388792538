import { EntryPointProps } from '@smart/components-adb/atoms/EntryPoint/EntryPoint.config';
import type { LinkOut } from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut.config';
import { SMART_COM_LINKS } from '@utils/configs/config';
import { TFunction } from 'i18next';

export const getEntryPoints = (t: TFunction): (LinkOut & EntryPointProps)[] => [
  {
    id: 'new_customer',
    name: t('dashboard.entry_points.new_customer'),
    path: '/customer/register',
    icon: 'new-customer',
    dataLayerRegion: 'entry_point',
  },
  {
    id: 'new_test_drive',
    name: t('dashboard.entry_points.new_test_drive'),
    path: 'test-drive/car-selection',
    icon: 'test-drive',
    dataLayerRegion: 'entry_point',
  },
  {
    id: 'customizer',
    name: t('dashboard.entry_points.customizer'),
    path: '#',
    icon: 'filter',
    dataLayerRegion: 'entry_point',
  },
  {
    id: 'smart_store',
    name: t('dashboard.entry_points.smart_store'),
    path: '#',
    icon: 'shopping-cart',
    isLinkOut: true,
    redirectPath: SMART_COM_LINKS.smartStore,
    dataLayerRegion: 'entry_point',
  },
];
