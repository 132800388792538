import {
  getPageNameFromPathname,
  IPageData,
  pageNameToPageTypeMap,
} from '@utils/analytics/pushToDataLayer';
import { useTracking } from '@utils/hooks/use-tracking';
import { useCurrentOutlet } from 'hooks/outlet';
import {
  createContext,
  useEffect,
  useRef,
  useState,
  type PropsWithChildren,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useAgentContext } from './agent-context';

export const PageViewTrackContext = createContext(null);

export const PageViewTrackContextProvider = ({
  children,
}: PropsWithChildren) => {
  const location = useLocation();
  const [referrerPath, setReferrerPath] = useState<string | null>(null);

  const { agent } = useAgentContext();
  const outlet = useCurrentOutlet();
  const [extendDataLayer] = useTracking<IPageData>();

  const previousPathnameRef = useRef<undefined | string>();

  useEffect(
    () => {
      const pageName = getPageNameFromPathname(location.pathname);

      const isNewPath =
        previousPathnameRef.current === undefined ||
        previousPathnameRef.current !== location.pathname;

      if (agent && pageName && outlet && isNewPath) {
        extendDataLayer({
          name: pageName,
          type: pageNameToPageTypeMap[pageName],
          referringPage: referrerPath,
          url: window.location.href,
        });

        setReferrerPath(location.pathname);
        previousPathnameRef.current = location.pathname;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, outlet]
  );

  return (
    <PageViewTrackContext.Provider value={null}>
      {children}
    </PageViewTrackContext.Provider>
  );
};
