import { AppointmentType, TaskCustomer } from '@smart/adb-shared';
import CreateAppointmentDialog from '@smart/components-adb/calendar/CreateAppointmentDialog/CreateAppointmentDialog';
import { getInitialAppointmentTime } from '@smart/components-adb/calendar/CreateAppointmentDialog/CreateAppointmentDialog.config';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button } from '@smart/react-components';
import {
  getDateXMonthsAfter,
  getDateXMonthsBefore,
} from '@ui/library/helpers/date';
import { EventType, IProductEventData } from '@utils/analytics/pushToDataLayer';
import { useTracking } from '@utils/hooks/use-tracking';
import { CalendarContextProvider } from 'contexts/calendar-context';
import { addMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { TaskActionProps } from '../types';

export const TestDriveButton = ({
  customer,
  task,
}: Omit<
  TaskActionProps & { customer: TaskCustomer },
  'onComplete' | 'disabled'
>) => {
  const { t } = useTranslation();
  const { registerModal } = useModal();
  const [extendDataLayer] = useTracking<IProductEventData>();

  const originalTask = task.original;
  const showLightReg = !originalTask.isAccountAssigned;

  return (
    <Button
      variant="primary"
      onClick={() => {
        extendDataLayer(
          {
            type: 'feature',
            region: 'tasks',
            name: 'lead_request.next_step.book_test_drive',
            product: [
              {
                id: task.original?.vehicle?.id || '',
                name: task.original?.vehicle?.productName || '',
                model: task.original?.vehicle?.model || '',
                color: [
                  {
                    interior: task.original?.vehicle?.interior || '',
                    exterior: task.original?.vehicle?.exterior || '',
                  },
                ],
              },
            ],
          },
          EventType.CLICK
        );
        registerModal(
          <CalendarContextProvider>
            <CreateAppointmentDialog
              initialShowLightReg={showLightReg}
              initialData={{
                appointmentType: AppointmentType.TestDrive,
                carId: '',
                startDate: getInitialAppointmentTime(new Date()).toISOString(),
                outletId: originalTask.outlet?.id ?? '',
                searchedCustomer: '',
                customerFirstName: originalTask.customer.firstName,
                customerLastName: originalTask.customer.lastName,
                customerPhoneNumber: originalTask.customer.phoneNumber ?? '',
                customerEmail: originalTask.customer.userId,
                endDate: getInitialAppointmentTime(
                  addMinutes(new Date(), 30)
                ).toISOString(),
                customerId: customer.uuid ?? undefined,
                expertId: originalTask.agent.gigyaId ?? '',
              }}
              calendarDateRange={[
                getDateXMonthsBefore(2),
                getDateXMonthsAfter(6),
              ]}
              taskId={originalTask.id}
            />
          </CalendarContextProvider>
        );
      }}
    >
      {t('test_drive.summary.buttons.confirm')}
    </Button>
  );
};
