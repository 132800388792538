import { MarketCode } from '@utils/market/types';
import { matchPath } from 'react-router-dom';

declare global {
  interface Window {
    adobeDataLayer: any;
  }
}

export type PageType =
  | 'general'
  | 'home'
  | 'customer_area'
  | 'testdrive'
  | 'orders'
  | 'stock'
  | 'outlet';

export type PageName =
  | 'home'
  | 'calendar'
  | 'tasks'
  | 'customer'
  | 'customer.register'
  | 'customer.overview'
  | 'customer.detail'
  | 'customer.documents'
  | 'customer.orders'
  | 'customer.purchase_history'
  | 'testdrive.vehicle'
  | 'testdrive.timeslot'
  | 'testdrive.account'
  | 'testdrive.overview'
  | 'testdrive.confirmation'
  | 'orders'
  | 'stock'
  | 'outlet.demo_cars'
  | 'outlet.details'
  | 'outlet.order_status'
  | 'outlet.documents';

export const pathnameToPageNameMap: Record<string, PageName> = {
  '/': 'home',
  '/calendar': 'calendar',
  '/tasks': 'tasks',
  '/customer': 'customer',
  '/customer/register': 'customer.register',
  '/customer/overview/:customerId/:sfCustomerId': 'customer.overview',
  '/customer/overview/:customerId/:sfCustomerId/:bpid/:sfOrgId':
    'customer.overview',
  '/customer/details/:customerId/:sfCustomerId': 'customer.detail',
  '/customer/details/:customerId/:sfCustomerId/:bpid/:sfOrgId':
    'customer.detail',
  '/customer/documents/:customerId/:sfCustomerId': 'customer.documents',
  '/customer/documents/:customerId/:sfCustomerId/:bpid/:sfOrgId':
    'customer.documents',
  '/customer/orders/:customerId/:sfCustomerId': 'customer.orders',
  '/customer/orders/:customerId/:sfCustomerId/:bpid/:sfOrgId':
    'customer.orders',
  '/customer/purchased-products/:customerId/:sfCustomerId':
    'customer.purchase_history',
  '/customer/purchased-products/:customerId/:sfCustomerId/:bpid/:sfOrgId':
    'customer.purchase_history',
  '/test-drive/car-selection': 'testdrive.vehicle',
  '/test-drive/time/car-time-selection': 'testdrive.timeslot',
  '/test-drive/confirmation/user-details': 'testdrive.account',
  '/test-drive/confirmation/summary/:customerId/:sfCustomerId':
    'testdrive.overview',
  '/test-drive/confirmation/summary/:customerId/:sfCustomerId/:bpid/:sfOrgId':
    'testdrive.overview',
  '/test-drive/confirmation/success': 'testdrive.confirmation',
  '/orders': 'orders',
  '/stock': 'stock',
  '/outlet/outlet-cars': 'outlet.demo_cars',
  '/outlet/details-and-team': 'outlet.details',
  '/outlet/order-status': 'outlet.order_status',
  '/outlet/documents': 'outlet.documents',
};

export const pageNameToPageTypeMap: Record<PageName, PageType> = {
  home: 'home',
  calendar: 'general',
  tasks: 'general',
  customer: 'customer_area',
  'customer.register': 'customer_area',
  'customer.overview': 'customer_area',
  'customer.detail': 'customer_area',
  'customer.documents': 'customer_area',
  'customer.orders': 'customer_area',
  'customer.purchase_history': 'customer_area',
  'testdrive.vehicle': 'testdrive',
  'testdrive.timeslot': 'testdrive',
  'testdrive.account': 'testdrive',
  'testdrive.overview': 'testdrive',
  'testdrive.confirmation': 'testdrive',
  orders: 'orders',
  stock: 'stock',
  'outlet.demo_cars': 'outlet',
  'outlet.details': 'outlet',
  'outlet.order_status': 'outlet',
  'outlet.documents': 'outlet',
};

export const getPageNameFromPathname = (
  pathname: string
): PageName | undefined => {
  const matchingKey = Object.keys(pathnameToPageNameMap).find((key) =>
    matchPath(key, pathname)
  );

  return matchingKey ? pathnameToPageNameMap[matchingKey] : undefined;
};

type OutletData = {
  id: string;
  name: string;
};

export enum EventType {
  PAGEVIEW = `pageview`,
  CLICK = `click`,
}

export type TrackingUserData = {
  readonly loginStatus: 'logged_in';
  readonly customerId: string;
  readonly type: 'agent';
  readonly channel: 'adb';
  readonly outlet: OutletData[];
};

export interface DataLayerPageViewEvent<T extends TrackTypes> {
  readonly event: EventType;
  readonly eventInfo?: T;
  readonly page?: T;

  readonly user: TrackingUserData;

  readonly application: {
    readonly id: 'adb';
    readonly version: string;
    readonly env: string;
    readonly language: string;
    readonly market: MarketCode;
    readonly currency: string;
  };
}

export interface IPageData {
  readonly name: PageName;
  readonly type: PageType;
  readonly referringPage: string | null;
  readonly url: string;
}

export interface IEventData {
  type: string;
  region: string;
  name: string;
  url: string;
  destinationUrl?: string;
  form?: {
    name: string;
    type: string;
  };
}

export interface IProductEventData {
  type: string;
  region: string;
  name: string;
  product: Array<{
    id?: string;
    name?: string;
    model?: string;
    color?: [
      {
        interior?: string;
        exterior?: string;
      },
    ];
    type?: string;
    priceUnit?: string;
    qty?: string;
    priceTotal?: string;
  }>;
}

export type EventCall<T extends TrackTypes> = (
  data: T,
  eventType?: EventType
) => void;

export type TrackTypes = IEventData | IPageData | IProductEventData;

export const pushToDataLayer = (
  event: DataLayerPageViewEvent<TrackTypes>
): void => {
  ((window as any).adobeDataLayer = (window as any).adobeDataLayer || []).push(
    event
  );
};

export const isTrackAvailable = (
  eventType: string,
  name: string,
  region: string
): boolean => {
  const getSpecifiedTrack = window.adobeDataLayer?.filter(
    (item: any) =>
      item[eventType]?.name === name && item[eventType]?.region === region
  );
  if (getSpecifiedTrack?.length > 0) {
    return true;
  }
  return false;
};
