import { CustomerAccountType } from '@smart/adb-shared';
import {
  buildLinkOutConfig,
  type LinkOutConfig,
} from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut.config';
import { NavItem } from '@smart/components-adb/molecules/TopNavigation/TopNavigation.config';
import { CustomerNavigationType } from '@utils/types/navigation';
import { useAgentContext } from 'contexts/agent-context';
import { useAuthContext } from 'contexts/auth-context';
import { useCustomerContext } from 'contexts/customer-context';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import { AdbLayoutBgColor } from 'layouts/spacing-layout/AdbLayout.config';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CustomerNavigation from '../../pages/customer/navigation';
import SectionLayout from '../section-layout/SectionLayout';
import type { CustomerLayoutProps } from './CustomerLayout.config';
import './CustomerLayout.scss';
import HeroBanner from './hero-banner/HeroBanner';

const BASE_CLASS = 'adb-customer-layout';

const CustomerLayout = ({ children, bannerType }: CustomerLayoutProps) => {
  const { customer } = useCustomerContext();
  const { auth } = useAuthContext();
  const { customerId, sfCustomerId, bpid, sfOrgId } = useParams();
  const navList: NavItem[] = CustomerNavigation(
    customerId,
    sfCustomerId,
    bpid,
    sfOrgId
  );

  const { agent } = useAgentContext();
  const { market } = useMarketContext();

  const { locale } = useLanguageContext();

  const referrerPath = useLocation().pathname;

  const linkOutConfig: LinkOutConfig = buildLinkOutConfig({
    language: locale,
    market: market ?? '',
    outletId: agent?.outletId,
    referrerPath,
    customerUserId: customer?.userId,
    customerType: customer?.accountType,
    customerUUID: customer?.uuid,
    accessToken: auth ? auth['access-token'] : undefined,
    idToken: auth ? auth['id-token'] : undefined,
    refreshToken: auth ? auth['refresh-token'] : undefined,
  });

  const updatedNavItems = useMemo(
    () =>
      navList
        .map((item) =>
          item.isLinkOut ? { ...item, config: linkOutConfig } : item
        )
        .filter((item) => {
          if (customer?.accountType === CustomerAccountType.Fleet)
            return item.name !== CustomerNavigationType.GARAGE;

          if (customer?.accountType === CustomerAccountType.Outlet)
            return ![
              CustomerNavigationType.DETAILS,
              CustomerNavigationType.GARAGE,
              CustomerNavigationType.CART,
            ].includes(item.name as CustomerNavigationType);

          return item;
        }),
    [customer?.accountType, linkOutConfig, navList]
  );

  const customerDetailsNavItem = updatedNavItems.find(
    (item) => item.name === CustomerNavigationType.DETAILS
  );

  const getCurrentPageName = () =>
    updatedNavItems.find((navItem) => navItem.path === referrerPath)?.name;

  const getBackgroundColor = (
    element: 'header' | 'content'
  ): AdbLayoutBgColor | undefined => {
    switch (getCurrentPageName()) {
      case CustomerNavigationType.OVERVIEW:
        return element === 'header' ? 'bg-level-1' : 'bg-level-3';

      case CustomerNavigationType.DETAILS:
        return element === 'header' ? 'bg-level-3' : 'bg-level-1';

      case CustomerNavigationType.DOCUMENTS:
        return element === 'header' ? 'bg-level-3' : 'bg-level-1';

      case CustomerNavigationType.ORDERS:
      case CustomerNavigationType.PRODUCTS:
        return element === 'header' ? 'bg-level-3' : 'bg-level-2';

      default:
        return 'bg-level-1';
    }
  };

  const dontWrapChildren =
    getCurrentPageName() === CustomerNavigationType.ORDERS ||
    getCurrentPageName() === CustomerNavigationType.PRODUCTS ||
    getCurrentPageName() === CustomerNavigationType.DOCUMENTS;

  return (
    <div className={BASE_CLASS}>
      <SectionLayout navItems={updatedNavItems} darkMode isCustomerLayout />
      <AdbLayout.Header backgroundColor={getBackgroundColor('header')} compact>
        <HeroBanner
          navItem={customerDetailsNavItem ?? ({} as NavItem)}
          bannerType={bannerType}
        />
      </AdbLayout.Header>
      {dontWrapChildren ? (
        <div className={`${BASE_CLASS}__children`}>{children}</div>
      ) : (
        <AdbLayout.Content backgroundColor={getBackgroundColor('content')}>
          {children}
        </AdbLayout.Content>
      )}
    </div>
  );
};

export default CustomerLayout;
