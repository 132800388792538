import {
  Customer,
  CustomerAccountType,
  CustomerPrivate,
  CustomerRecordType,
  CustomerType,
  DmsCustomerByIdInput,
} from '@smart/adb-shared';
import AdbLink from '@smart/components-adb/atoms/AdbLink/AdbLink';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { Button } from '@smart/react-components';
import { EventType, IEventData } from '@utils/analytics/pushToDataLayer';
import { useTracking } from '@utils/hooks/use-tracking';
import classNames from 'classnames';
import { useNotificationContext } from 'contexts/notification-context';
import { useCustomerRegistrationMutation } from 'graphql/queries/customer.generated';
import { useCurrentOutlet } from 'hooks/outlet';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import BasicInfo from '../details/BasicInfo/BasicInfo';
import type {
  CustomerBusinessNewForm,
  CustomerPrivateNewForm,
} from '../details/helper';
import { CustomerFormTypes } from '../details/helper';
import { getCustomerPayload } from '../helper';
import './CustomerRegister.scss';
import { useDmsCustomerQuery } from './queries/dmsCustomer.generated';
import { emptyPrivateCustomer } from './register.config';

const BASE_CLASS = 'adb-new-customer';

type CustomerLocationState = {
  uuid: string;
  customerType?: string;
  customer?: Customer;
  dmsCustomer: DmsCustomerByIdInput;
};

const CustomerRegister = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [extendDataLayer] = useTracking<IEventData>();

  // get the uuid of the customer type lead for transformation to full account.
  const customerLocationState = location.state as CustomerLocationState;

  const [customerDetails, setCustomerDetails] = useState<Customer>();

  const { data: customerDmsDetails, loading: customerDetailssDmsLoading } =
    useDmsCustomerQuery({
      variables: {
        input: customerLocationState?.dmsCustomer,
      },
      skip:
        customerLocationState?.customerType !== 'DMS' ||
        !customerLocationState?.dmsCustomer,
    });

  const dmsCustomerDetails = customerDmsDetails?.dmsCustomer;

  useEffect(() => {
    if (customerDetailssDmsLoading) return;
    if (
      customerLocationState?.uuid &&
      customerLocationState.customerType === CustomerType.Dms
    ) {
      // setCustomerDetails(dmsCustomerDetails);
    } else if (customerLocationState?.uuid) {
      setCustomerDetails({
        type: CustomerType.Lead,
        ...customerLocationState.customer,
      } as CustomerPrivate);
    } else {
      setCustomerDetails(emptyPrivateCustomer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dmsCustomerDetails, customerDetailssDmsLoading]);

  const [consentValue, setConsentValue] = useState<boolean>(false);

  const [
    registerCustomer,
    {
      data: registerCustomerMutationData,
      loading: registerCustomerMutationLoading,
    },
  ] = useCustomerRegistrationMutation();

  const isLoading =
    registerCustomerMutationLoading || customerDetailssDmsLoading;

  const outlet = useCurrentOutlet();
  /* submit function to dispatch the action to API
   * type of updated form values is defined in the helper file
   */
  const handleCustomerDetailSubmit = (
    updatedFormValues: CustomerPrivateNewForm | CustomerBusinessNewForm
  ) => {
    const customerInput = getCustomerPayload(
      {
        __typename:
          updatedFormValues.accountType === CustomerAccountType.Business
            ? 'CustomerBusiness'
            : 'CustomerPrivate',
        ...updatedFormValues,
      },
      outlet,
      consentValue
    );

    registerCustomer({
      variables: {
        input: customerInput,
      },
    });
  };

  const { addNotification } = useNotificationContext();

  useEffect(() => {
    if (
      registerCustomerMutationLoading ||
      !registerCustomerMutationData?.customerRegistration
    )
      return;

    if (registerCustomerMutationData.customerRegistration.success) {
      extendDataLayer(
        {
          type: 'form',
          region: 'customers',
          name: 'submit.success',
          url: window.location.href,
          form: {
            name: 'new_customer',
            type: 'registration',
          },
        },
        EventType.CLICK
      );

      addNotification({
        label: t('customer.register.success'),
        type: 'success',
        message: `${t('customer.register.save_customer_success')}`,
      });

      setTimeout(() => {
        navigate('/customer', {
          replace: true,
        });
      }, 3000);
    } else {
      addNotification({
        label: t('customer.register.error'),
        type: 'error',
        message: `${t('customer.register.save_customer_error')}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerCustomerMutationData, registerCustomerMutationLoading]);

  /* form footer buttons passed as props to basic info component  */
  const formFooter = (formType?: CustomerFormTypes, disabled?: boolean) => (
    <div className={`${BASE_CLASS}__action-item`}>
      <Button variant="secondary" disabled={false} type="reset">
        {t('customer.register.buttons.reset')}
      </Button>

      <Button
        variant="primary"
        type="submit"
        disabled={disabled}
        loading={isLoading}
      >
        <Button.Spinner />
        {t('customer.register.buttons.create_new_customer')}
      </Button>
    </div>
  );

  /* Common form props used by multiple sub forms */
  const formProps = {
    formFooter,
    handleCustomerDetailSubmit,
  };

  const customerDetailsProps = {
    ...formProps,
    customerDetails: {
      __typename: customerDetails?.__typename,
      firstName: customerDetails?.firstName ?? '',
      lastName: customerDetails?.lastName ?? '',
      market: customerDetails?.market ?? '',
      mobileNumber: customerDetails?.mobileNumber ?? '',
      preferredLanguage: customerDetails?.preferredLanguage ?? '',
      titleCode: customerDetails?.titleCode ?? '',
      userId: customerDetails?.userId ?? '',
      uuid: customerDetails?.uuid ?? '',
      lastModified: customerDetails?.lastModified ?? '',
      recordType:
        customerDetails?.recordType ?? CustomerRecordType.PersonAccount,
      sfCustomerId: customerDetails?.sfCustomerId ?? '',
      type: customerDetails?.type ?? CustomerType.Account,
      street: customerDetails?.address?.street ?? '',
      town: customerDetails?.address?.town ?? '',
      countryCode: customerDetails?.address?.countryCode ?? '',
      postalCode: customerDetails?.address?.postalCode ?? '',
      regionCode: customerDetails?.address?.regionCode ?? '',
      accountType: customerDetails?.accountType ?? CustomerAccountType.Private,
      customerType: customerLocationState?.customerType,
      sfOrgId:
        customerDetails?.__typename === 'CustomerBusiness'
          ? customerDetails?.sfOrgId
          : '',
      bpid:
        customerDetails?.__typename === 'CustomerBusiness'
          ? customerDetails?.bpid
          : '',
      companyName:
        customerDetails?.__typename === 'CustomerBusiness'
          ? customerDetails?.companyName
          : '',
      vatRegistrationNumber:
        customerDetails?.__typename === 'CustomerBusiness'
          ? customerDetails?.vatRegistrationNumber
          : '',
      companyRegistrationNumber:
        customerDetails?.__typename === 'CustomerBusiness'
          ? customerDetails?.companyRegistrationNumber
          : '',
    },
    title: `${t('customer.register.new_customer')}`,
    isNotAccordion: true,
    radioButtonTab: true,
    consent: consentValue,
    onConsentChange: () => setConsentValue(!consentValue),
  };

  const customerLoading =
    customerDetailssDmsLoading ||
    customerDetails === undefined ||
    (customerLocationState?.customerType === 'DMS' &&
      dmsCustomerDetails === undefined);

  return (
    <AdbLayout.Content>
      <div
        className={classNames(`${BASE_CLASS}__container`, {
          [`${BASE_CLASS}__container--loading`]: isLoading,
        })}
      >
        {customerLoading ? (
          <LoadingIndicator
            onFullPage
            additionalClasses={`${BASE_CLASS}__loading`}
          />
        ) : (
          <BasicInfo {...customerDetailsProps} />
        )}
      </div>
      {isLoading && (
        <LoadingIndicator
          onFullPage
          additionalClasses={`${BASE_CLASS}__loading`}
        />
      )}
      {!customerDetailssDmsLoading && (
        <div className={`${BASE_CLASS}__back-btn-wrap`}>
          <AdbLink
            title={t('general.labels.back')}
            path="/customer"
            icon={{ icon: 'arrow-left' }}
            iconPosition="left"
          />
        </div>
      )}
    </AdbLayout.Content>
  );
};

export default CustomerRegister;
