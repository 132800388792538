import { Text } from '@smart/react-components';
import { EventType, IEventData } from '@utils/analytics/pushToDataLayer';
import { useTracking } from '@utils/hooks/use-tracking';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ListItem, UnOrderedList } from '../../atoms/List/List';
import { PageNavigationProps } from './PageNavigation.config';
import './PageNavigation.scss';

const BASE_CLASS = 'adb-page-nav';

const PageNavigation = ({ pageItems, activeItem }: PageNavigationProps) => {
  const { t } = useTranslation();
  const [extendDataLayer] = useTracking<IEventData>();
  const localUrl = window.location.href;

  return (
    <nav className={BASE_CLASS}>
      <UnOrderedList>
        {pageItems.map((item) => (
          <ListItem
            key={item.headline}
            content={
              <NavLink
                to={item.path}
                className={classNames(`${BASE_CLASS}__link`, {
                  [`${BASE_CLASS}__link--active`]: item.path === activeItem,
                  [`${BASE_CLASS}__link--inactive`]: item.path !== activeItem,
                })}
                onClick={() => {
                  if (item?.dataLayerName)
                    extendDataLayer(
                      {
                        type: 'navigation',
                        region: item?.dataLayerRegion || '',
                        name: item?.dataLayerName,
                        url: localUrl,
                        destinationUrl: item.path,
                      },
                      EventType.CLICK
                    );
                }}
              >
                <Text variant="lbl-100" as="p" color="c-high-contrast">
                  {t(item.headline)}
                </Text>
              </NavLink>
            }
          />
        ))}
      </UnOrderedList>
    </nav>
  );
};

export default PageNavigation;
