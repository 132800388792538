import { AppointmentType, Task } from '@smart/adb-shared';
import CalendarModalWrapper from '@smart/components-adb/calendar/CalendarModal';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Button } from '@smart/react-components';
import { Row } from '@tanstack/react-table';
import { EventType, IProductEventData } from '@utils/analytics/pushToDataLayer';
import { useTracking } from '@utils/hooks/use-tracking';
import { useTranslation } from 'react-i18next';
import { isAppointmentCompleted } from '../utils';

export const TestDriveRescheduleCancelButton = ({
  task,
}: {
  task: Row<Task>;
}) => {
  const { t } = useTranslation();
  const { registerModal } = useModal();
  const [extendDataLayer] = useTracking<IProductEventData>();

  const { customer, appointment, vin } = task.original;

  return (
    <Button
      variant="primary"
      mode={200}
      disabled={!appointment || isAppointmentCompleted(task.original)}
      onClick={() => {
        if (appointment) {
          extendDataLayer(
            {
              type: 'feature',
              region: 'tasks',
              name: 'test_drive.next_step.reschedule-cancel',
              product: [
                {
                  id: task.original?.vehicle?.id || '',
                  name: task.original?.vehicle?.productName || '',
                  model: task.original?.vehicle?.model || '',
                  color: [
                    {
                      interior: task.original?.vehicle?.interior || '',
                      exterior: task.original?.vehicle?.exterior || '',
                    },
                  ],
                },
              ],
            },
            EventType.CLICK
          );
          registerModal(
            <CalendarModalWrapper
              customerId={customer.uuid ?? ''}
              appointmentType={AppointmentType.TestDrive}
              carId={vin}
              preSelectedAppointmentId={
                task.original.appointment?.id ?? undefined
              }
              preSelectedStartTime={task.original.scheduledDate ?? undefined}
            />
          );
        }
      }}
    >
      {`${t('feature_calendar.general.buttons.reschedule')} / ${t('feature_calendar.general.buttons.cancel')}`}
    </Button>
  );
};
